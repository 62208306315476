/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    object,
} from 'prop-types';
import loadable from '@loadable/component';
import mbpLogger from 'mbp-logger';
import useStyles from 'isomorphic-style-loader/useStyles';
import tallCss from './Tall.module.css';
import ResponsiveWrapper from '../../../../../SubComponents/ResponsiveWrapper';
import universalHandleClick from '../../../../../UniversalHandleClick';

const MessageStandard = loadable(() => import(/* webpackChunkName: "MessageStandard" */ '../../../../../SubComponents/Message/MessageStandard'));

const TallVideoBanner = ({ data }) =>     {
    if (!Object.keys(data)?.length) {
        return mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'Banner/1up/Video/Tall/Tall.js',
            message: 'No data available to render component',
        });
    }
    useStyles(tallCss);

    const videoUrlWebm = {
        desktop: data.video?.desktop?.webm?.url,
        tablet: data.video?.use_mobile_video_for_tablet ? data.video?.mobile?.webm?.url :  data.video?.tablet?.webm?.url, // can add fallbacks easily this way
        mobile: data.video?.mobile?.webm?.url,
    };
    const videoUrlMp4 = {
        desktop: data.video?.desktop.mp4?.url,
        tablet: data.video?.use_mobile_video_for_tablet ? data.video?.mobile?.mp4?.url :  data.video?.tablet?.mp4?.url, // can add fallbacks easily this way
        mobile: data.video?.mobile?.mp4?.url,
    };
    const device = (matches) => Object.keys(matches).find((key) => matches[key]); // returns device type string

    const handleClick = universalHandleClick();
    const renderBanner = (matches) => (

        <>
            <div className={tallCss.videoBanner}>
                {videoUrlMp4[device(matches)] && (
                    <video data-testid="video-box" autoPlay loop muted width="100%" height="100%" className={tallCss.objectFit}>
                        <source src={videoUrlWebm[device(matches)]} type={data.video?.desktop?.webm?.content_type} preload="metadata" />
                        <source src={videoUrlMp4[device(matches)]} type={data.video?.desktop?.mp4?.content_type} preload="metadata" />
                        <track kind="captions" />
                    </video>
                )}
                {Object.keys(data.message)?.length > 0 &&  <MessageStandard data={data.message} />}
            </div>
        </>
    );
    return (
        <div data-testid="tallVideoBanner" role="presentation" className={tallCss.videoContainer} onClick={() => handleClick(data.message.cta[0])}>
            <ResponsiveWrapper renderer={renderBanner} />
        </div>
    );
};
TallVideoBanner.propTypes = {
    data: object.isRequired,
};
export default TallVideoBanner;
